<template>
  <div>
    <div class="payment-detail-content">
      <div class="heading">
        <div class="left-heading text-start">
          <h2>Edit Divisions</h2>
        </div>
      </div>
      <div class="modal-dialog modal-dialog-centered dialog-edit-user">
        <div class="modal-content">
          <div class="modal-body text-start">
            <div>
              <div class="row d-flex justify-content-center">
                <p class="text-center" style="font-weight: bold">{{ name }}</p>
              </div>
              <div class="row mb-4">
                <div class="col-6 mb-2">PM Emails</div>
                <div class="table-content">
                  <table class="table color-8B9DA5">
                    <tbody>
                      <tr
                        v-for="(pm, index) in listPm"
                        :key="index"
                        class="justify-content-center"
                      >
                        <td class="pt-3 pb-3 align-middle" style="width: 90%">
                          {{ pm.pmEmail }}
                        </td>
                        <td class="pt-3 pb-3">
                          <button class="btn" @click="handleRemovePm(pm)">
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="button-group d-flex justify-content-around">
                <button type="button" class="btn btn-warning text-white float-end" @click="showModal()">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-edit-announcement">
      <div
        class="modal fade"
        :class="{ show: isShowModal }"
        id="edit-announcement-modal"
        tabindex="-1"
        aria-labelledby="edit-announcement-modal"
        aria-hidden="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="padding: 16px">
            <span style="font-weight: bold p-2">Add PM Email</span>
            <div class="modal-body">
              <input
                class="form-control"
                type="email"
                required
                v-model="addEmail"
                placeholder="Email"
                @blur="validateEmail"
                @input="debouncedQuery"
              />
              <span class="error text-left f-w3 mt-2 ml" v-show="isShowErrorEmpty" style="float: left;"> Email can't be empty </span>
              <span class="error text-left f-w3 mt-2" v-show="isShowErrorNotEmail" style="float: left;"> Email format is incorrect </span>
            </div>
            <div class="button-group d-flex justify-content-around">
              <button type="button" class="btn background-C4C4C4 me-2" @click="hideModel()">
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-warning text-white float-end"
                @click="handleAddPm()"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteIcon from "@/components/Icon/DeleteIcon";
import {MESSAGES, MODAL} from "@/utils/const";
export default {
  name: "EditDivision",
  components: {
    DeleteIcon,
  },
  data() {
    return {
      id: null,
      isShowModal: false,
      isShowErrorEmpty: false,
      isShowErrorNotEmail: false,
      name: "",
      addEmail: '',
      listPm: [],
    };
  },
  mounted() {},
  created() {
    this.getListPMs();
  },
  methods: {
    getListPMs() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("dashboard/getListPMs", this.$route.params.id)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.listPm = response;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    showModal() {
      this.isShowModal = true;
    },
    hideModel() {
      this.addEmail = '';
      this.isShowErrorEmpty = false;
      this.isShowErrorNotEmail = false;
      this.isShowModal = false;
    },
    handleAddPm() {
      if (this.addEmail === '') {
        this.isShowErrorEmpty = true;
        return
      } else if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.addEmail)) {
        this.isShowErrorNotEmail = true;
        return
      }
      this.isShowModal = false;
      this.$root.$refs.loading.start();
      let body = {
        id: this.$route.params.id,
        addPms: [
          this.addEmail
        ]
      }
      this.addEmail = '';
      console.log(this.addEmail)
      this.$store
        .dispatch("dashboard/updateListPms", body)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.$toast(
            MESSAGES.ADD_PM_SUCCESSFUL,
            MODAL.MODAL_SUCCESS,
            MODAL.MODAL_TYPE_SUCCESS
          );
          this.listPm = response;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.$toast(
              MESSAGES.PM_EXISTED,
              MODAL.MODAL_SUCCESS,
              MODAL.MODAL_TYPE_DANGER
            );
          } 
          this.$root.$refs.loading.finish();
        });
    },
    handleRemovePm(pm) {
      this.isShowModal = false;
      this.$root.$refs.loading.start();
      let body = {
        id: this.$route.params.id,
        removePms: [
          pm.pmEmail
        ]
      }
      this.$store
        .dispatch("dashboard/updateListPms", body)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.$toast(
            MESSAGES.DELETE_PM_SUCCESSFUL,
            MODAL.MODAL_SUCCESS,
            MODAL.MODAL_TYPE_SUCCESS
          );
          this.listPm = response;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    validateEmail() {
      if (this.addEmail === '') {
        this.isShowErrorEmpty = true
      } else
      if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.addEmail)) {
        this.isShowErrorNotEmail = true
      }
    },
    debouncedQuery () {
      this.isShowErrorEmpty = false;
      this.isShowErrorNotEmail = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/projects/detail.scss";

.btn-payback {
  width: 90px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background: #000000;
  border-radius: 8px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.box-content {
  width: 429px;
  background: #ffffff;
  border: 1px solid #ecf1f3;
  box-sizing: border-box;
  box-shadow: 3px 0px 10px rgba(223, 223, 223, 0.3);
  border-radius: 10px;
  margin: 20px auto;
  padding: 25px 30px;
}

.button-group button {
  width: 165px;
}

.w-100 {
  width: 100%;
}

.box-row {
  height: 52px;
}

.flex-container {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-middle {
  align-items: center;
}

.header-content-failed {
  margin-top: 10px;
}

.content-failed {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  background: #eaeaea;
  padding: 10px;
  border-radius: 8px;
}

#edit-announcement-modal {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  &.show {
    display: block;
    padding-left: 0;
    transition: all 0.2s;
  }

  .modal-body {
    padding: 20px;
  }

  .dialog-edit-user {
    width: 400px;
  }

  input,
  textarea {
    color: #8b9da5;
    border: 1px solid #ecf1f3;
    border-radius: 8px;
  }

  .detail {
    border: none;
    margin-top: 0;
  }

  .button-group button {
    width: 165px;
  }

  .input-group-text {
    background-color: #fff;
  }

  input[type="text"]:not(:last-child) {
    margin-top: 5px;
  }

  .modal-title {
    text-align: center;
    margin-top: 25px;
    font-weight: bold;
    font-size: 18px;
  }

  .modal-content {
    border-radius: 10px;
    padding: 16px;
  }

  .modal-content-add {
    padding: 16px;
  }
}
</style>